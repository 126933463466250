import API from '../modules/API';

export default () => {
	return {
		name: 'Immheader',
		template: require('./header.html'),
		data() {
			return {
				links: [
					{ name: 'Brèves', url: '/breves' },
					{ name: 'Actualités', url: '/actualites' },
					{ name: 'Présentation', url: '/presentation' },
					//{ name: 'Nous Contacter', url: `mailto:${this.Config.contactEmail}` },
				],
			};
		},
		created() {
		},
		mounted() {
		},
		methods: {
			async logout() {
				try {
					await API.logout();
					this.$authUser.user = null;
					this.$router.replace('/');
				} catch (exception) {
					this.authError = exception;
				}
			},

		},
	};
};
