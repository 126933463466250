import API from '../../modules/API';
import ImmFormBreve from '../../components/imm-form-breve';

export default () => {
	return {
		name: 'BreveEdit',
		template: require('./breveEdit.html'),
		components: {
			ImmFormBreve,
		},
		data() {
			return {
				breve: null,
			};
		},
		created() {
			this.getBreve(this.$route.params.id);
		},
		mounted() {
		},
		methods: {
			async getBreve(id) {
				try {
					this.breve = await API.getBreve(id);
				} catch (exception) {
					console.error(exception);
				}
			},
			async deleteBreve(id) {
				try {
					await API.remove(id);
					this.$router.push('/profil/breves/');
				} catch (e) {
					console.log("TODO: Error Raised", e);
				}
			},
			async validateBreve(id) {
				try {
					await API.editBreve(id);
					this.$router.push('/profil/breves/');
				} catch (e) {
					console.log("TODO: Error Raised", e);
				}
			},
		},
	};
};
