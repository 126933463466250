import isEmpty from '../modules/isEmpty';
import API from '../modules/API';
import ImmGender from './imm-gender';
import ImmAddress from './imm-address';

export default {
	name: 'ImmFormContact',
	template: require('./imm-form-contact.html'),
	props: ['modelValue'],
	emits: ['onClose'],
	components: {
		ImmGender,
		ImmAddress,
	},
	data() {
		return {
			_contact: {
				gender: 'Monsieur',
				sectorType: 'private',
				sector: '',
			},
			sector: '',
			sectorList: {
				private: [
					['Promoteur', 'Investisseur institutionnel', 'Bailleur social', 'Bailleur privé', 'Foncière', 'Family Office', 'SCI'],
					['Expert - conseil - Immobilier', 'Expert - conseil - Dépollution - Désamiantage', 'Expert - conseil - AMO'],
					['Aménageur', 'Architectes / Architecte-urbaniste'],
				],
				public: [
					['Commune/Communautés de communes', 'Ville', 'Communauté d\'agglomération', 'Agglomérations', 'Métropole'],
					['SEM', 'SPL', 'EPA', 'EPF - EPFA', 'EPT', 'Entreprise publique']
				]
			},
			formError: null,
			loadingCommit: false,
			passwd: [
				{ value: '', isValid: null, textContent: '' },
				{ value: '', isValid: null, textContent: '' },
			],
			showPasswd: false,
		};
	},
	computed: {
		isEdit() {
			return isEmpty(this.modelValue) === false;
		},
		contact() {
			return this.isEdit === true ? this.modelValue : this._contact;
		}
	},
	methods: {
		async contactAdd() {
			this.formError = null;
			this.loadingCommit = true;
			try {
				if (this.isEdit === false) {
					const contact = await API.addContact(this.contact);
					this.$emit('onClose', contact);
				} else {
					const contact = await API.editContact(this.contact.id, this.contact);
					this.$emit('onClose', contact);
				}
			} catch (exception) {
				this.formError = exception;
			} finally {
				this.loadingCommit = false;
			}
		},
	},
};
