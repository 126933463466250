import ImmFormUser from '../../components/imm-form-user';

export default () => {
	return {
		name: 'UserAdd',
		template: require('./userAdd.html'),
		components: {
			ImmFormUser,
		},
		data() {
			return {
			};
		},
		created() {
		},
		mounted() {
		},
		methods: {
		},
	};
};
