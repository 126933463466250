import API from '../../modules/API';
import isEmpty from '../../modules/isEmpty';
import Paginator from '../../modules/paginator';
import ImmPagination from '../../components/imm-pagination';

const LIMIT = 10;

export default () => {
	return {
		name: 'Contacts',
		template: require('./contacts.html'),
		components: {
			ImmPagination,
		},
		data() {
			return {
				searchContact: '',
				contactList: null,
				contactsError: null,
				pagination: new Paginator(),
			};
		},
		watch: {
			'searchContact'() {
				this.onSearchContact();
			},
		},
		async created() {
			await this.getContacts();
		},
		methods: {
			async getContacts() {
				const query = {
					$sort: {
						_dateInsert: -1,
					},
				};

				query.$limit = LIMIT;
				query.$skip = this.pagination.currentPage * LIMIT;
				if (query.$skip < 0) {
					query.$skip = 0;
				}

				if (isEmpty(this.searchContact) === false) {
					this.pagination.currentPage = 0;
					query.$skip = 0;
					query.$or = [
						{company: {
								$search: '(?i).*' + this.searchContact + '.*',
							},
						},
						{firstName: {
								$search: '(?i).*' + this.searchContact + '.*',
							},
						},
						{name: {
								$search: '(?i).*' + this.searchContact + '.*',
							},
						},
						{email: {
								$search: '(?i).*' + this.searchContact + '.*',
							},
						},
					];
				}

				this.contactsError = null;
				try {
					this.contactList = await API.getContacts(query);
					this.pagination.update(this.contactList);
				} catch (exception) {
					this.contactsError = exception;
				}
			},
			async deleteContact(id) {
				this.contactsError = null;
				try {
					await API.removeContact(id);
					await this.getContacts();
				} catch (exception) {
					this.contactsError = exception;
				}
			},
			async onSearchContact() {
				await this.getContacts();
			},
		},
	};
};
