import API from '../modules/API';
import zxcvbn from 'zxcvbn';
import isEmpty from '../modules/isEmpty';

export default () => {
	return {
		name: 'LostPassword',
		template: require('./lostpassword.html'),
		data() {
			return {
				email: null,
				password: null,
				formError: null,
				formSuccess: null,
				hashError: null,
				loadingCommit: false,
			};
		},
		async created() {
			await this.checkHash(this.$route.params.id, this.$route.params.hash);
		},
		methods: {
			async checkHash(userId, hash) {
				this.hashError = null;

				try {
					const res = await API.checkHash(userId, hash);
					if (isEmpty(res) === true || res.success !== true) {
						this.hashError = res;
					}
					this.email = res.email;
				} catch (exception) {
					this.hashError = exception;
				}
			},
			checkPass(num) {
				let el;
				if (num === 1) {
					el = this.$refs.frm.password;
				}
				if (num === 2) {
					el = this.$refs.frm.passwordRepeat;
				}
				this.$refs[`pass${num}Feedback`].classList.remove('valid-feedback');
				this.$refs[`pass${num}Feedback`].classList.remove('invalid-feedback');
				el.classList.remove('is-valid');
				el.classList.remove('is-invalid');

				const checkP = zxcvbn(el.value);
				//console.log('checkP:', checkP);
				//  0 # too guessable: risky password. (guesses < 10^3)
				//  1 # very guessable: protection from throttled online attacks. (guesses < 10^6)
				//  2 # somewhat guessable: protection from unthrottled online attacks. (guesses < 10^8)
				//  3 # safely unguessable: moderate protection from offline slow-hash scenario. (guesses < 10^10)
				//  4 # very unguessable: strong protection from offline slow-hash scenario. (guesses >= 10^10)

				if (checkP.score < 3) {
					this.$refs[`pass${num}Feedback`].classList.add('invalid-feedback');
					this.$refs[`pass${num}Feedback`].textContent = 'Complexitée trop faible';
					this.$refs[`pass${num === 2 ? 1 : 2}Feedback`].textContent = '';
					el.classList.add('is-invalid');
					return false;
				}

				if (num === 1 && isEmpty(this.$refs.frm.passwordRepeat.value) === false) {
					if (this.$refs.frm.password.value !== this.$refs.frm.passwordRepeat.value) {
						this.$refs[`pass${num}Feedback`].classList.add('invalid-feedback');
						this.$refs[`pass${num}Feedback`].textContent = 'Mots de passe non identiques';
						el.classList.add('is-invalid');
						return false;
					}
				}
				if (num === 2 && isEmpty(this.$refs.frm.password.value) === false) {
					if (this.$refs.frm.password.value !== this.$refs.frm.passwordRepeat.value) {
						this.$refs[`pass${num}Feedback`].classList.add('invalid-feedback');
						this.$refs[`pass${num}Feedback`].textContent = 'Mots de passe non identiques';
						el.classList.add('is-invalid');
						return false;
					}
				}
				if (this.$refs.frm.password.value === this.$refs.frm.passwordRepeat.value) {
					this.$refs[`pass1Feedback`].classList.add('valid-feedback');
					this.$refs[`pass1Feedback`].textContent = '';
					this.$refs[`pass2Feedback`].classList.add('valid-feedback');
					this.$refs[`pass2Feedback`].textContent = '';
					this.$refs.frm.password.classList.remove('is-invalid');
					this.$refs.frm.passwordRepeat.classList.remove('is-invalid');
					this.$refs.frm.password.classList.add('is-valid');
					this.$refs.frm.passwordRepeat.classList.add('is-valid');
				}

				this.$refs[`pass${num}Feedback`].classList.add('valid-feedback');
				this.$refs[`pass${num}Feedback`].textContent = '';
				el.classList.add('is-valid');
				return true;
			},
			async resetPassword() {
				if (this.checkPass(1) === false || this.checkPass(2) === false) {
					this.formError = 'Mots de passe invalides';
					return;
				}

				this.formError = null;
				this.formSuccess = null;
				this.loadingCommit = true;
				try {
					const data = {
						password: this.$refs.frm.password.value,
						hash: this.$route.params.hash,
						updatePassword: true,
					};
					const output = await API.updatePassword(this.email, data);
					if (isEmpty(output) === false && output.success === true) {
						this.formSuccess = 'Mot de passe réinitialisé';
					}
					else {
						this.formError = 'Erreur';
					}
				} catch (exception) {
					this.formError = exception;
				} finally {
					this.loadingCommit = false;
				}
			},
		},
	};
};
