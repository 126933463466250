import API from '../../modules/API';
import isEmpty from '../../modules/isEmpty';
import Paginator from '../../modules/paginator';
import ImmPagination from '../../components/imm-pagination';

const LIMIT = 10;

export default () => {
	return {
		name: 'Users',
		template: require('./users.html'),
		components: {
			ImmPagination,
		},
		data() {
			return {
				searchUser: '',
				memberGroup: '',
				userList: null,
				usersError: null,
				group: {
					adm: {style: 'bg-primay', text: 'Admin'},
					user: {style: 'bg-breves', text: 'Validé'},
					waiting: {style: 'bg-actualites', text: 'En attente'},
				},
				pagination: new Paginator(),
			};
		},
		watch: {
			'searchUser'() {
				this.getUsers();
			},
		},
		async created() {
			await this.getUsers();
		},
		methods: {
			async getUsers() {
				const query = {
					$sort: {
						_dateInsert: -1,
					},
				};

				query.$limit = LIMIT;
				query.$skip = this.pagination.currentPage * LIMIT;
				if (query.$skip < 0) {
					query.$skip = 0;
				}

				if (isEmpty(this.memberGroup) === false) {
					query.group = this.memberGroup;
				}

				if (isEmpty(this.searchUser) === false) {
					this.pagination.currentPage = 0;
					query.$skip = 0;
					query.$or = [
						{name: {
								$search: '(?i).*' + this.searchUser + '.*',
							},
						},
						{firstName: {
								$search: '(?i).*' + this.searchUser + '.*',
							},
						},
						{email: {
								$search: '(?i).*' + this.searchUser + '.*',
							},
						},
					];
				}

				this.usersError = null;
				try {
					this.userList = await API.getUsers(query);
					this.pagination.update(this.userList);
				} catch (exception) {
					this.usersError = exception;
				}
			},
			async deleteUser(id) {
				this.usersError = null;
				try {
					await API.removeUser(id);
					await this.getUsers();
				} catch (exception) {
					this.usersError = exception;
				}
			},
			async onGroupChange(group) {
				this.pagination.currentPage = 0;
				this.memberGroup = group;
				this.searchUser = '';
				
				await this.getUsers();
			},
		},
	};
};
