import API from '../../modules/API';
import isEmpty from '../../modules/isEmpty';
import Paginator from '../../modules/paginator';
import ImmShowBreve from '../../components/imm-show-breve';
import ImmPagination from '../../components/imm-pagination';

const LIMIT = 10;

export default () => {
	return {
		name: 'BrevesList',
		template: require('./breves.html'),
		components: {
			ImmShowBreve,
			ImmPagination,
		},
		data() {
			return {
				searchBreve: '',
				breveFilter: '',
				breveList: null,
				breveError: null,
				pagination: new Paginator(),
			};
		},
		watch: {
			'searchBreve'() {
				this.getBreves();
			},
		},
		async created() {
			await this.getBreves();
		},
		methods: {
			async getBreves() {
				const query = {
					$sort: {
						_dateInsert: -1,
					},
					status: '*',
				};

				query.$limit = LIMIT;
				query.$skip = this.pagination.currentPage * LIMIT;
				if (query.$skip < 0) {
					query.$skip = 0;
				}

				if (isEmpty(this.breveFilter) === false) {
					if (this.breveFilter === 'ads') {
						query.adOn = true;
					} else {
						query.status = this.breveFilter;
					}
				}

				if (isEmpty(this.searchBreve) === false) {
					this.pagination.currentPage = 0;
					query.$skip = 0;
					query.$or = [
						{title: {
								$search: '(?i).*' + this.searchBreve + '.*',
							},
						},
						{desc: {
								$search: '(?i).*' + this.searchBreve + '.*',
							},
						},
						{project: {
								$search: '(?i).*' + this.searchBreve + '.*',
							},
						},
						{object: {
								$search: '(?i).*' + this.searchBreve + '.*',
							},
						},
					];
				}

				this.breveError = null;
				try {
					this.breveList = await API.getBreves(query);
					this.pagination.update(this.breveList);
				} catch (exception) {
					this.breveError = exception;
				}
			},
			async deleteBreve(id) {
				if (window.confirm('Voulez-vous confirmer la suppression de la brève ?') === true) {
					this.breveError = null;
					try {
						await API.removeBreve(id);
						await this.getBreves();
					} catch (exception) {
						this.breveError = exception;
					}
				}
			},
			async togglePublished(breve) {
				const status = (breve.status === 'published') ? 'waiting' : 'published';

				this.breveError = null;
				try {
					await API.editBreve(breve.id, {status});
					breve.status = status;
				} catch (exception) {
					this.breveError = exception;
				}
			},
			async onGroupChange(group) {
				this.pagination.currentPage = 0;
				this.searchBreve = '';
				this.breveFilter = group;

				await this.getBreves();
			},
		},
	};
};
