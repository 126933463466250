
export default {
	name: 'ImmPagination',
	template: require('./imm-pagination.html'),
	props: ['pagination', 'onRefresh'],
	methods: {
		hasPrev() {
			return this.pagination.currentPage > 0;
		},
		hasNext() {
			return this.pagination.currentPage + 1 < this.pagination.pagesTotal;
		},
		prevPage() {
			this.pagination.currentPage--;
			this.onRefresh();
		},
		nextPage() {
			this.pagination.currentPage++;
			this.onRefresh();
		},
		goToPage(page) {
			this.pagination.currentPage = page;
			this.onRefresh();
		},
	},
};
