import isEmpty from './isEmpty';

export default class Paginator {
	constructor() {
		this.pagesTotal = 0;
		this.currentPage = 0;
	}
	update(data) {
		if (isEmpty(data) === true) {
			this.pagesTotal = 0;
			this.currentPage = 0;
			return;
		}
		this.pagesTotal = Math.ceil(data.total / data.limit);
		this.currentPage = data.skip / data.limit;
	}
}
