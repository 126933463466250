import API from '../modules/API';
import ImmAdActualites from '/src/components/imm-ad-actualites';
import ImmAdBreves from '/src/components/imm-ad-breves';

export default {
	name: 'ImmCarouselAd',
	template: require('./imm-carousel-ad.html'),
	props: ['category'],
	components: {
		ImmAdActualites,
		ImmAdBreves,
	},
	data() {
		return {
			// ads
			ads: null,
			adsError: null,
			id: '',
		};
	},
	async created() {
		this.id = 'carouselAd-' + this.category;

		if (this.category === 'actualites') {
			await this.getAdsActualites();
		} else if (this.category === 'breves') {
			await this.getAdsBreves();
		}

		if (this.ads !== null) {
			const el = this.$refs.carousel;
			const carousel = new window.mdb.Carousel(el);
			carousel.cycle();
		}
	},
	methods: {
		async getAdsActualites() {
			this.adsError = null;
			try {
				const actuAds = await API.getAdsActualites({
					status: 'published',
				});
				if (actuAds.total > 0) {
					this.ads = actuAds.data;
				}
			} catch (exception) {
				this.adsError = exception;
			}
		},
		async getAdsBreves() {
			this.adsError = null;
			try {
				const breveAds = await API.getAdsBreves({
					status: 'published',
				});
				if (breveAds.total > 0) {
					this.ads = breveAds.data;
				}
			} catch (exception) {
				this.adsError = exception;
			}
		},
	},
};
