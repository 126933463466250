import API from '../modules/API';
import ImmShowActu from '/src/components/imm-show-actu';
import ImmCarouselAd from '/src/components/imm-carousel-ad';

// same config as actualites.hooks.all.limitResults.js
const PAGINATION = {
	MAX_PAGE: 3,
	limit: 10,
};

export default () => {
	return {
		name: 'ActuList',
		template: require('./actu.html'),
		components: {
			ImmShowActu,
			ImmCarouselAd,
		},
		watch: {
			async '$route.params.id'() {
				await this.onCreated();
			},
			async '$route.query.page'() {
				const page = parseInt(this.$route.query.page);
				if (isNaN(page) === false) {
					await this.appendResults(parseInt(this.$route.query.page));
				} else {
					await this.appendResults(1);
				}
			},
		},
		data() {
			return {
				actu: null,
				actus: null,
				actuError: null,
			};
		},
		computed: {
			realPage() {
				return PAGINATION.MAX_PAGE;
			},
		},
		created() {
			this.onCreated();
		},
		methods: {
			async onCreated() {
				this.actu = null;
				this.actus = null;

				if (this.isEmpty(this.$route.params.id) === false) {
					this.actu = await this.getActu(this.$route.params.id);
				} else {
					let page = parseInt(this.$route.query.page);
					if (isNaN(page) === true) {
						page = 1;
					}
					this.actus = await this.getActus(0, page * PAGINATION.limit);
				}
			},
			async getActu(id) {
				this.actuError = null;
				try {
					return await API.getActu(id);
				} catch (exception) {
					this.actuError = exception;
				}
				return null;
			},
			async getActus($skip = 0, $limit = PAGINATION.limit) {
				this.actuError = null;
				try {
					return await API.getActus({
						$skip,
						$limit,
						adOn: '$hasnotfieldorfalse',
					});
				} catch (exception) {
					this.actuError = exception;
				}
				return null;
			},
			hasMore(answer) {
				return this.actus.data.length < answer.total;
			},
			isOverMore() {
				return this.$route.query.page >= PAGINATION.MAX_PAGE;
			},
			async showMore() {
				let page = parseInt(this.$route.query.page);
				if (isNaN(page) === true) {
					page = 2;
				} else {
					page++;
				}
				this.$router.push({query: {page}});
			},
			async appendResults(page) {
				if (this.actus === null) {
					this.actus = {data: []};
				}
				//console.log('appendResults:', page, this.actus.data.length, page * PAGINATION.limit);

				// ask page already fetched
				if (page * PAGINATION.limit <= this.actus.data.length) {
					//console.log('slice', 0, page * PAGINATION.limit);
					this.actus.data = this.actus.data.slice(0, page * PAGINATION.limit);
				} else {
					//console.log('append');
					const newItems = await this.getActus(this.actus.data.length, (page * PAGINATION.limit) - this.actus.data.length);
					if (newItems !== null) {
						if (this.actus === null) {
							this.actus = {data: []};
						}
						this.actus.data = [...this.actus.data, ...newItems.data];
						this.actus.skip = newItems.skip;
					}
				}
			},
		},
	};
};
