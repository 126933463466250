import zxcvbn from 'zxcvbn';
import isEmpty from '../modules/isEmpty';
import API from '../modules/API';
import ImmGender from './imm-gender';
import ImmAddress from './imm-address';

export default {
	name: 'ImmFormUser',
	template: require('./imm-form-user.html'),
	props: ['modelValue', 'returnURL'],
	components: {
		ImmGender,
		ImmAddress,
	},
	data() {
		return {
			_user: {
				gender: 'Monsieur',
				sectorType: 'private',
				sector: '',
			},
			sector: '',
			sectorList: {
				private: [
					['Promoteur', 'Investisseur institutionnel', 'Bailleur social', 'Bailleur privé', 'Foncière', 'Family Office', 'SCI'],
					['Expert - conseil - Immobilier', 'Expert - conseil - Dépollution - Désamiantage', 'Expert - conseil - AMO'],
					['Aménageur', 'Architectes / Architecte-urbaniste'],
				],
				public: [
					['Commune/Communautés de communes', 'Ville', 'Communauté d\'agglomération', 'Agglomérations', 'Métropole'],
					['SEM', 'SPL', 'EPA', 'EPF - EPFA', 'EPT', 'Entreprise publique']
				]
			},
			formError: null,
			loadingCommit: false,
			passwd: [
				{ value: '', isValid: null, textContent: '' },
				{ value: '', isValid: null, textContent: '' },
			],
			showPasswd: false,
			addressSearchDisabled: false,
		};
	},
	computed: {
		isEdit() {
			return isEmpty(this.modelValue) === false;
		},
		user() {
			return this.isEdit === true ? this.modelValue : this._user;
		}
	},
	methods: {
		checkPass(num) {
			let el;
			if (num === 1) {
				el = this.$refs.frm.password;
			}
			if (num === 2) {
				el = this.$refs.frm.passwordRepeat;
			}
			this.$refs[`pass${num}Feedback`].classList.remove('valid-feedback');
			this.$refs[`pass${num}Feedback`].classList.remove('invalid-feedback');
			el.classList.remove('is-valid');
			el.classList.remove('is-invalid');

			const checkP = zxcvbn(el.value);
			//console.log('checkP:', checkP);
			//  0 # too guessable: risky password. (guesses < 10^3)
			//  1 # very guessable: protection from throttled online attacks. (guesses < 10^6)
			//  2 # somewhat guessable: protection from unthrottled online attacks. (guesses < 10^8)
			//  3 # safely unguessable: moderate protection from offline slow-hash scenario. (guesses < 10^10)
			//  4 # very unguessable: strong protection from offline slow-hash scenario. (guesses >= 10^10)

			if (checkP.score < 3) {
				this.$refs[`pass${num}Feedback`].classList.add('invalid-feedback');
				this.$refs[`pass${num}Feedback`].textContent = 'Complexitée trop faible';
				this.$refs[`pass${num === 2 ? 1 : 2}Feedback`].textContent = '';
				el.classList.add('is-invalid');
				return false;
			}

			if (num === 1 && isEmpty(this.$refs.frm.passwordRepeat.value) === false) {
				if (this.$refs.frm.password.value !== this.$refs.frm.passwordRepeat.value) {
					this.$refs[`pass${num}Feedback`].classList.add('invalid-feedback');
					this.$refs[`pass${num}Feedback`].textContent = 'Mots de passe non identiques';
					el.classList.add('is-invalid');
					return false;
				}
			}
			if (num === 2 && isEmpty(this.$refs.frm.password.value) === false) {
				if (this.$refs.frm.password.value !== this.$refs.frm.passwordRepeat.value) {
					this.$refs[`pass${num}Feedback`].classList.add('invalid-feedback');
					this.$refs[`pass${num}Feedback`].textContent = 'Mots de passe non identiques';
					el.classList.add('is-invalid');
					return false;
				}
			}
			if (this.$refs.frm.password.value === this.$refs.frm.passwordRepeat.value) {
				this.$refs[`pass1Feedback`].classList.add('valid-feedback');
				this.$refs[`pass1Feedback`].textContent = '';
				this.$refs[`pass2Feedback`].classList.add('valid-feedback');
				this.$refs[`pass2Feedback`].textContent = '';
				this.$refs.frm.password.classList.remove('is-invalid');
				this.$refs.frm.passwordRepeat.classList.remove('is-invalid');
				this.$refs.frm.password.classList.add('is-valid');
				this.$refs.frm.passwordRepeat.classList.add('is-valid');
			}

			this.$refs[`pass${num}Feedback`].classList.add('valid-feedback');
			this.$refs[`pass${num}Feedback`].textContent = '';
			el.classList.add('is-valid');
			return true;
		},
		async userAdd() {
			if (this.isEdit !== true) {
				if (this.checkPass(1) === false || this.checkPass(2) === false) {
					this.formError = 'Mots de passe invalides';
					return;
				}
			}

			delete this.user.passwordRepeat;

			this.formError = null;
			this.loadingCommit = true;
			try {
				if (this.isEdit === false) {
					await API.addUser(this.user);

					if (this.isEmpty(this.returnURL) === true) {
						this.$router.go(-1);
					} else {
						this.$router.push(this.returnURL);
					}
				} else {
					await API.editUser(this.user.id, this.user);
					this.$router.push('/profil/users');
				}
			} catch (exception) {
				this.formError = exception;
			} finally {
				this.loadingCommit = false;
			}
		},
	},
};
