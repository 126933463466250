
export default () => {
	return {
		name: 'registerWaiting',
		template: require('./registerWaiting.html'),
		data() {
			return {
			};
		},
		async created() {
		},
		mounted() {
		},
		methods: {
		},
	};
};
