
export default {
	name: 'ImmAdActualites',
	template: require('./imm-ad-actualites.html'),
	props: ['ad'],
	data() {
		return {
		};
	},
	created() {
	},
	mounted() {
	},
	methods: {
	},
};
