
export default {
	name: 'ImmContact',
	template: require('./imm-contact.html'),
	props: ['modelValue'],
	computed: {
		contact() {
			return this.modelValue;
		},
		fullName() {
			return `${this.modelValue.firstName ? this.modelValue.firstName : ''} ${this.modelValue.name ? this.modelValue.name.toUpperCase() : ''}`;
		},
	}
};
