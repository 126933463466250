import API from '../modules/API';
import isEmpty from '../modules/isEmpty';

let modalLostP = null;

export default () => {
	return {
		name: 'Profil',
		template: require('./profil.html'),
		data() {
			return {
				authError: null,
				counters: {
					// users
					adminsValidated: null,
					membersValidated: null,
					membersWaiting: null,
					// actus
					actusPublished: null,
					actusWaiting: null,
					// breves
					brevesValidated: null,
					brevesWaiting: null,
					// contacts
					contacts: null
				},
				showPasswd: false,
				lostPError: null,
				lostPText: null,
				lostPLoading: false,
			};
		},
		watch: {
			async '$authUser.user'() {
				if (this.isConnected() === true) {
					await this.getCounters();
				}
			},
		},
		async created() {
			if (this.isConnected() === true) {
				await this.getCounters();
			}
		},
		methods: {
			async onConnect() {
				const currentTarget = this.$refs.form;

				const email = currentTarget.email.value.trim();
				const password = currentTarget.password.value.trim();

				if (this.isEmpty(email) === true) {
					this.authError = 'Email incorrect';
					return;
				}
				if (this.isEmpty(password) === true) {
					this.authError = 'Mot de passe incorrect';
					return;
				}

				// AUTH
				try {
					const auth = await API.login(email, password);
					if (auth === null) {
						this.authError = 'Mot de passe invalide';
						return;
					}
					this.$authUser.user = auth.user;

					if (this.isEmpty(this.$route.query.breveId) === false) {
						this.$router.replace(`/breves/${this.$route.query.breveId}`);
					}
					if (this.isEmpty(this.$route.query.actuPage) === false) {
						this.$router.replace(`/actualites?page=${this.$route.query.actuPage}`);
					}
				} catch (exception) {
					this.authError = this.errorManager(exception);
				}
			},
			async onLostPasswordOpen() {
				const currentTarget = this.$refs.form;

				const email = currentTarget.email.value.trim();

				if (this.isEmpty(email) === false) {
					this.$refs.lostPEmail.value = email;
				}

				this.lostPText = null;
				modalLostP = new window.mdb.Modal(this.$refs.lostPModal);
				modalLostP.show();
			},
			async onLostPassword() {
				//console.log('onLostPassword!', ev.currentTarget);
				const email = this.$refs.lostPEmail.value.trim();

				if (this.isEmpty(email) === true) {
					this.lostPError = 'Email invalide';
					return;
				}

				this.lostPError = null;
				this.lostPText = null;
				this.lostPLoading = true;

				try {
					const res = await API.resetPassword(email);
					if (isEmpty(res) === true || typeof res.success !== 'boolean' || res.success !== true) {
						this.lostPError = res;
					}

					this.lostPText = 'email envoyé';
					window.setTimeout(() => {
						if (modalLostP !== null) {
							modalLostP.hide();
							modalLostP = null;
						}
					}, 2000);
				} catch (exception) {
					this.lostPError = this.errorManager(exception);
				}

				this.lostPLoading = false;
			},
			async getCounters() {
				// users
				this.counters.adminsValidated = await this.getCountersMembers('adm');
				this.counters.membersValidated = await this.getCountersMembers('user');
				this.counters.membersWaiting = await this.getCountersMembers('waiting');
				// actus
				this.counters.actusPublished = await this.getCountersActus('published');
				this.counters.actusWaiting = await this.getCountersActus('waiting');
				// breves
				this.counters.brevesValidated = await this.getCountersBreves('published');
				this.counters.brevesWaiting = await this.getCountersBreves('waiting');
				// contacts
				this.counters.contacts = await this.getCountersContacts();
			},
			async getCountersMembers(group) {
				const query = {
					$limit: 0,
					group,
				};
				try {
					const data = await API.getUsers(query);
					return data.total;
				} catch (exception) {
					console.error(exception);
				}
			},
			async getCountersActus(status) {
				const query = {
					$limit: 0,
					status,
				};
				try {
					const data = await API.getActus(query);
					return data.total;
				} catch (exception) {
					console.error(exception);
				}
			},
			async getCountersBreves(status) {
				const query = {
					$limit: 0,
					status,
				};
				try {
					const data = await API.getBreves(query);
					return data.total;
				} catch (exception) {
					console.error(exception);
				}
			},
			async getCountersContacts() {
				const query = {
					$limit: 0,
				};
				try {
					const data = await API.getContacts(query);
					return data.total;
				} catch (exception) {
					console.error(exception);
				}
			},
		},
	};
};
