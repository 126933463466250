import ImmShowBreve from '/src/components/imm-show-breve';

export default {
	name: 'ImmAdBreve',
	template: require('./imm-ad-breves.html'),
	components: {
		ImmShowBreve,
	},
	props: ['ad'],
	data() {
		return {
		};
	},
};
