import API from '../../modules/API';
import ImmFormContact from '../../components/imm-form-contact';

export default () => {
	return {
		name: 'ContactEdit',
		template: require('./contactEdit.html'),
		components: {
			ImmFormContact,
		},
		data() {
			return {
				contact: null,
			};
		},
		created() {
			this.getContact(this.$route.params.id);
		},
		mounted() {
		},
		methods: {
			async getContact(id) {
				try {
					this.contact = await API.getContact(id);
				} catch (exception) {
					console.error(exception);
				}
			},
			async deleteContact(id) {
				try {
					await API.removeContact(id);
					this.$router.push('/profil/contacts/');
				} catch (e) {
					console.log("TODO: Error Raised", e);
				}
			},
		},
	};
};
