import API from '../../modules/API';
import ImmFormUser from '../../components/imm-form-user';

export default () => {
	return {
		name: 'UserEdit',
		template: require('./userEdit.html'),
		components: {
			ImmFormUser,
		},
		data() {
			return {
				user: null,
			};
		},
		created() {
			this.getUser(this.$route.params.id);
		},
		mounted() {
		},
		methods: {
			async getUser(id) {
				try {
					this.user = await API.getUser(id);
				} catch (exception) {
					console.error(exception);
				}
			},
			async deleteUser(id) {
				try {
					await API.removeUser(id);
					this.$router.push('/profil/users/');
				} catch (e) {
					console.log("TODO: Error Raised", e);
				}
			},
			async validateUser(id) {
				try {
					await API.editUser(id, {group: 'user'});
					this.$router.push('/profil/users/');
				} catch (e) {
					console.log("TODO: Error Raised", e);
				}
			},
			async invalidateUser(id) {
				try {
					await API.editUser(id, {group: 'waiting'});
					this.$router.push('/profil/users/');
				} catch (e) {
					console.log("TODO: Error Raised", e);
				}
			},
		},
	};
};
