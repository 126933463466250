import Config from './config';
import feathers from '@feathersjs/client';
import rest from '@feathersjs/rest-client';

const app = feathers();
const restClient = rest(Config.serverRest);
app.configure(restClient.fetch(window.fetch.bind(window)));

app.configure(feathers.authentication({
	storage: window.localStorage,
}));

export default {
	async authenticate() {
		try {
			return await app.authenticate();
		} catch (ex) {
			console.log('Auth Err', ex);
			return null;
		}
	},
	async logout() {
		try {
			return await app.logout({strategy: 'local'});
		} catch (ex) {
			return null;
		}
	},
	async login(email, password) {
		try {
			return await app.reAuthenticate();
		} catch (error) {
			// do noting
		}

		return await app.authenticate({
			strategy: 'local',
			email,
			password,
		});
	},
	async addBreve(breve) {
		return await app.service('breves').create(breve);
	},
	async getBreves(query = {}) {
		if (typeof query.$sort === 'undefined') {
			query.$sort = {
				_dateInsert: -1,
			};
		}

		return await app.service('breves').find({query});
	},
	async getBreve(id) {
		return await app.service('breves').get(id);
	},
	async removeBreve(id) {
		return await app.service('breves').remove(id);
	},
	async editBreve(id, data) {
		return await app.service('breves').patch(id, data);
	},
	async getAdsBreves(query = {}) {
		if (typeof query.$sort === 'undefined') {
			query.$sort = {
				_dateInsert: -1,
			};
		}
		query.adOn = true;

		return await app.service('breves').find({query});
	},
	async getAdsActualites(query = {}) {
		if (typeof query.$sort === 'undefined') {
			query.$sort = {
				_dateInsert: -1,
			};
		}
		query.adOn = true;

		return await app.service('actualites').find({query});
	},
	async addActu(actu) {
		return await app.service('actualites').create(actu);
	},

	async getActus(query = {}) {
		if (typeof query.$sort === 'undefined') {
			query.$sort = {
				_dateInsert: -1,
			};
		}
		
		return await app.service('actualites').find({query});
	},
	async getActu(id) {
		return await app.service('actualites').get(id);
	},
	async removeActu(id) {
		return await app.service('actualites').remove(id);
	},
	async editActu(id, data) {
		return await app.service('actualites').patch(id, data);
	},

	// users
	async getUsers(query = {}) {
		if (typeof query.$sort === 'undefined') {
			query.$sort = {
				_dateInsert: -1,
			};
		}

		return await app.service('users').find({query});
	},
	async getUser(id) {
		return await app.service('users').get(id);
	},
	async addUser(user) {
		return await app.service('users').create(user);
	},
	async editUser(id, data) {
		return await app.service('users').patch(id, data);
	},
	async removeUser(id) {
		return await app.service('users').remove(id);
	},

	// lostpassword
	async resetPassword(email) {
		return await app.service('lostpassword').patch(email, {reset: true});
	},
	async checkHash(userId, hash) {
		return await app.service('lostpassword').get(userId + '/' + hash);
	},
	async updatePassword(email, data) {
		return await app.service('lostpassword').patch(email, data);
	},

	// contacts
	async getContacts(query = {}) {
		if (typeof query.$sort === 'undefined') {
			query.$sort = {
				name: -1,
			};
		}
		return await app.service('contacts').find({query});
	},
	async getContact(id) {
		return await app.service('contacts').get(id);
	},

	async addContact(contact) {
		return await app.service('contacts').create(contact);
	},
	async removeContact(id) {
		return await app.service('contacts').remove(id);
	},
	async editContact(id, data) {
		return await app.service('contacts').patch(id, data);
	},
	async searchContact(pattern) {
		const $search = `(?i)${pattern}`;
		return await app.service('contacts').find({
			query: {
				$or: [
					{name: {$search}},
					{firstName: {$search}},
					{email: {$search}},
					{emailPrivate: {$search}}
				]
			}
		});
	},
	
	// site
	async getSite(id) {
		return await app.service('site').get(id);
	},
	async editSite(id, data) {
		return await app.service('site').patch(id, data);
	},
};
