import API from '../../modules/API';
//import Quill from 'quill';

let WYSIWYGinst = null;

export default () => {
	return {
		name: 'Site',
		template: require('./site.html'),
		data() {
			return {
				siteContent: null,
				siteContentError: null,
				currentId: null,
			};
		},
		watch: {
		},
		async mounted() {
			const instances = await window.tinymce.init({
				selector: '#editor',
				...this.Config.tinymceOptions,
			});
			WYSIWYGinst = instances[0];

			await this.loadSite('presentation');
		},
		async unmounted() {
			WYSIWYGinst.destroy();
			WYSIWYGinst = null;
		},
		methods: {
			async loadSite(id) {
				this.siteContentError = null;
				try {
					this.currentId = id;
					this.siteContent = await API.getSite(id);

					if (this.isEmpty(this.siteContent) === false) {
						WYSIWYGinst.setContent(this.siteContent.html);
					} else {
						WYSIWYGinst.setContent('');
					}
				} catch (exception) {
					this.siteContentError = exception;
				}
			},
			async onSave() {
				if (WYSIWYGinst === null) {
					return null;
				}
				const html = WYSIWYGinst.getContent();
				if (this.isEmpty(html) === false) {
					this.siteContentError = null;
					try {
						await API.editSite(this.currentId, {html});

						const alertList = document.querySelectorAll('.alert');
						alertList.forEach((alert) => {
							const alertInst = new window.mdb.Alert(alert);
							alertInst.show();
							window.setTimeout(() => {
								alertInst.close();
							}, 2000);
						});
					} catch (exception) {
						this.siteContentError = exception;
					}
				}
			},
		}
	};
};
