import ImmContact from './imm-contact';
import API from '../modules/API';
import isEmpty from '../modules/isEmpty';
import ModalContact from './modal-contact';

export default {
	name: 'ImmContactList',
	template: require('./imm-contact-list.html'),
	props: ['modelValue'],
	components: {
		ImmContact,
		ModalContact
	},
	data() {
		return {
			searchContact: '',
			searchList: {
				total: 0
			},
			showList: false, // show and hide search list
			isLoading: false,
			itemClicked: false, // used to check if a contact action had been done
			contactList: [],
			showContactForm: false,
			contact: null,
		};
	},
	async created() {
		this.createContactList();
	},
	computed: {
		contactListSearch() {
			const idSet = new Set(this.contactListId);
			return this.searchList.data.filter(ct => idSet.has(ct.id) === false);
		},
		contactListId() {
			return this.modelValue;
		},
	},

	methods: {
		async createContactList() {
			if (isEmpty(this.contactListId) === false && this.contactListId.length !== 0) {
				const contactListSearch = await API.getContacts({
					id: { $in: this.modelValue }
				});
				this.contactList = contactListSearch.data;
			}
		},
		createContact() {
			this.contact = null;
			this.showContactForm = true;
		},
		addContact(contact) {
			this.showList = false;
			this.itemClicked = false;
			this.contactAction = true;
			if (isEmpty(this.contactListId.find(id => id === contact.id)) === true) {
				this.contactListId.push(contact.id);
				this.$emit('update:modelValue', this.contactListId);
			}
			this.createContactList();

		},
		removeContact(id) {
			this.showList = false;
			const itemIdx = this.contactList.findIndex(contact => contact.id === id);
			if (itemIdx >= 0) {
				this.contactList.splice(itemIdx, 1);
				this.$emit('update:modelValue', this.contactList.map(c => c.id));
			}
		},
		async findContacts() {
			try {
				this.isLoading = true;
				if (this.searchContact.length > 0) {
					this.searchList = await API.searchContact(this.searchContact);
				} else {
					this.searchList = await API.getContacts();
				}

				if (this.searchList.total > 0) {
					this.showList = true;
				}
			} catch (e) {
				console.log(e);
			} finally {
				this.isLoading = false;
			}
			return false;
		},
		showContact(contact) {
			this.contact = contact;
			this.showContactForm = true;
		},
		hideListSearch() {
			if (this.itemClicked === false) {
				// Note: to hide the list, we need to ensure no contact items had been clicked
				this.showList = false;
			}
		},
		async onCloseContactForm(contact) {
			this.showContactForm = false;
			await this.createContactList();
			this.addContact(contact);
		}
	}
};
