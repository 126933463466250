/* eslint-disable indent */

export default (exception) => {
	// not an exception
	if (typeof exception.message !== 'string') {
		return exception;
	}

	switch (exception.message) {
		case 'Failed to fetch':
			return 'Erreur de connexion au serveur - veuillez réessayer plus tard.';
		case 'Invalid login':
			return 'Mot de passe invalide.';
	}
	return exception.message;
};
