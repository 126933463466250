
export default {
	name: 'ImmUserGender',
	template: require('./imm-gender.html'),
	props: ['modelValue', 'name', 'readonly', 'required', 'disabled'],
	data() {
		return {
		};
	},
	computed: {
		gender: {
			get() {
				return this.modelValue;
			},
			set(v) {
				this.$emit('update:modelValue', v);
			}
		}
	},
};
