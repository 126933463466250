import API from '../modules/API';

export default {
	name: 'ImmFormActu',
	template: require('./imm-form-actu.html'),
	props: ['modelValue'],
	components: {
	},
	data() {
		return {
			formError: null,
			linkError: false,
			loadingCommit: false,
			_actu: {
				onlySubscriber: false,
				status: 'waiting',
			},
			// file upload
			fileNames: {
				image: '',
				imageAd: '',
			},
		};
	},
	computed: {
		isEdit() {
			return this.isEmpty(this.modelValue) === false;
		},
		actu() {
			return (this.isEdit === true) ? this.modelValue : this._actu;
		},
		published: {
			get() {
				return this.actu.status === 'published';
			},
			set(v) {
				this.actu.status = v === true ? 'published' : 'waiting';
			}
		}
	},
	methods: {
		async onActuAdd() {
			this.formError = null;
			this.loadingCommit = true;

			if (typeof this.actu.onlySubscriber === 'undefined') {
				this.actu.onlySubscriber = false;
			}

			try {
				if (this.isEdit === true) {
					await API.editActu(this.actu.id, this.actu);
				} else {
					await API.addActu(this.actu);
				}
				this.$router.replace(`/profil/actualites`);
			} catch (exception) {
				this.formError = this.errorManager(exception);
			} finally {
				this.loadingCommit = false;
			}
		},
		handleFileChange(event, type) {
			this.fileNames[type] = '';
			const file = event.files[0];
			if (this.isEmpty(file) === true) {
				return;
			}

			this.fileNames[type] = file.name;

			const reader = new FileReader();
			reader.readAsDataURL(file);

			reader.onload = (e) => {
				// base64 image encoding
				this.actu[type] = e.target.result;
			};
		},
		checkLink() {
			this.linkError = false;
			this.formError = null;
			
			if (this.isEmpty(this.actu.link) === false) {
				const regex = /^(http|https):\/\/[^ "]+$/;
				if (regex.test(this.actu.link) === false) {
					this.formError = 'Veuillez entrer un lien valide';
					this.linkError = true;
				}
			}
		},
	},
};
