const API_KEY = 'b95d72c8093246f996ba2ed3660dd68b';

export default async(query) => {

	const requestOptions = {
		method: 'GET',
	};

	try {
		const response = await fetch(`https://api.geoapify.com/v1/geocode/autocomplete?lang=fr&text=${query}&apiKey=${API_KEY}`, requestOptions);
		const json = await response.json();
		
		const addresses = json.features.map((a) => a.properties);

		//{
		//    "result_type": "postcode",
		//    "city": "La Valette-du-Var",
		//    "municipality": "Toulon",
		//    "county": "Var",
		//    "state": "Provence-Alpes-Côte d'Azur",
		//    "postcode": "83160",
		//    "country": "France",
		//    "country_code": "fr",
		//    "datasource": {
		//        "sourcename": "openstreetmap",
		//        "attribution": "© OpenStreetMap contributors",
		//        "license": "Open Database License",
		//        "url": "https://www.openstreetmap.org/copyright"
		//    },
		//    "state_code": "PAC",
		//    "state_COG": "93",
		//    "lon": 5.993596617,
		//    "lat": 43.132328188,
		//    "formatted": "83160 La Valette-du-Var, France",
		//    "address_line1": "83160 La Valette-du-Var",
		//    "address_line2": "France",
		//    "department_COG": "83",
		//    "category": "administrative",
		//    "timezone": {
		//        "name": "Europe/Paris",
		//        "offset_STD": "+01:00",
		//        "offset_STD_seconds": 3600,
		//        "offset_DST": "+02:00",
		//        "offset_DST_seconds": 7200,
		//        "abbreviation_STD": "CET",
		//        "abbreviation_DST": "CEST"
		//    },
		//    "plus_code": "8FM74XJV+WC",
		//    "plus_code_short": "JV+WC La Valette-du-Var, Var, France",
		//    "rank": {
		//        "confidence": 1,
		//        "confidence_city_level": 1,
		//        "match_type": "full_match"
		//    },
		//    "placeid": "5196b93d6471f917405944e64b21f0904540f00101f901af2a010000000000c00207"
		//}			
			
		return addresses;
	} catch (exception) {
		console.error(exception);
		return [];
	}
};
