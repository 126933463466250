import ImmFormBreve from '../../components/imm-form-breve';

export default () => {
	return {
		name: 'BreveAdd',
		template: require('./breveAdd.html'),
		components: {
			ImmFormBreve,
		},
	};
};
