import ImmFormContact from "./imm-form-contact";
export default {
	name: 'ModalContact',
	template: require('./modal-contact.html'),
	props: ['modelValue', 'item'],
	emits: ['onClose', 'update:modelValue'],
	components: { ImmFormContact },
	methods: {
		onClose(item) {
			this.$emit('onClose', item);
		},
		onEcsClose(ev) {
			if (ev.key === 'Escape') {
				this.$emit('update:modelValue', false);
			}
		}
	},
	mounted() {
		document.addEventListener('keydown', this.onEcsClose);
	},
	unmounted() {
		document.removeEventListener('keydown', this.onEcsClose);
	},
	computed: {
		isEdit() {
			return this.isEmpty(this.item) !== true;
		},
		show() {
			return this.modelValue === true;
		}
	},
	watch: {
		modelValue(v) {
			if (this.isEmpty(this.$refs.modal) === false && v === true) {
				this.$refs.modal.focus();
			}
		}
	}
};
