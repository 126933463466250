import API from '../modules/API';

export default () => {
	return {
		template: require('/src/pages/mentions.html'),
		data() {
			return {
				siteContent: null,
				siteContentError: null,
			};
		},
		async mounted() {
			await this.getSitePresentation();
			
			if (this.isEmpty(this.siteContent) === false) {
				this.$refs.htmlMentions.innerHTML = this.siteContent.html;
			}
		},
		methods: {
			async getSitePresentation() {
				this.siteContentError = null;
				try {
					this.siteContent = await API.getSite('mentions');
				} catch (exception) {
					this.siteContentError = exception;
				}
			},
		},
	};
};
