import { createRouter, createWebHistory } from 'vue-router';
import config from '../modules/config';

// pages
import Home from '../pages/home';
import About from '../pages/about';
import Profil from '../pages/profil';
import LostPassword from '../pages/lostpassword';

import Breves from '../pages/breves';
import Actu from '../pages/actu';
import Mentions from '../pages/mentions';
import Register from '../pages/register';
import RegisterWaiting from '../pages/registerWaiting';
import Users from '../pages/backoffice/users';
import UserAdd from '../pages/backoffice/userAdd';
import UserEdit from '../pages/backoffice/userEdit';
import BackOfficeActus from '../pages/backoffice/actus';
import BackOfficeActuAdd from '../pages/backoffice/actuAdd';
import BackOfficeActuEdit from '../pages/backoffice/actuEdit';
import BackOfficeBreves from '../pages/backoffice/breves';
import BackOfficeBreveAdd from '../pages/backoffice/breveAdd';
import BackOfficeBreveEdit from '../pages/backoffice/breveEdit';
import BackOfficeContacts from '../pages/backoffice/contacts';
import BackOfficeContactAdd from '../pages/backoffice/contactAdd';
import BackOfficeContactEdit from '../pages/backoffice/contactEdit';
import BackOfficeSite from '../pages/backoffice/site';
import PageNotFound from '../pages/pageNotFound';

const routes = [
	{path: '/', name: 'Accueil', component: Home()},
	{path: '/presentation', name: 'Présentation et Audience', component: About()},
	{path: '/mentions-legales', name: 'Mentions Légales', component: Mentions()},
	{path: '/breves/:id?', name: 'Brèves Projets', component: Breves()},
	{path: '/actualites/:id?', name: 'Actualités', component: Actu()},
	{path: '/register', name: 'Créer mon compte', component: Register()},
	{path: '/register/waiting', name: 'Compte créé', component: RegisterWaiting()},
	// backoffice
	{path: '/profil', name: 'Profil', component: Profil()},
	{path: '/profil/users', name: 'Gestion des membres', component: Users()},
	{path: '/profil/users/new', name: 'Ajout d\'un membre', component: UserAdd()},
	{path: '/profil/users/:id', name: 'Édition des membres', component: UserEdit()},
	{path: '/profil/actualites', name: 'Gestion des actualités', component: BackOfficeActus()},
	{path: '/profil/actualites/new', name: 'Ajout d\'une actualité', component: BackOfficeActuAdd()},
	{path: '/profil/actualites/:id', name: 'Édition d\'une actualité', component: BackOfficeActuEdit()},
	{path: '/profil/breves', name: 'Gestion des Brèves', component: BackOfficeBreves()},
	{path: '/profil/breves/new', name: 'Ajout d\'une brève', component: BackOfficeBreveAdd()},
	{path: '/profil/breves/:id', name: 'Édition des brèves', component: BackOfficeBreveEdit()},
	{path: '/profil/contacts/', name: 'Gestion des contacts', component: BackOfficeContacts()},
	{path: '/profil/contacts/new', name: 'Ajout d\'un contact', component: BackOfficeContactAdd()},
	{path: '/profil/contacts/:id', name: 'Édition d\'un contact', component: BackOfficeContactEdit()},
	{path: '/profil/lostpassword/:id/:hash', name: 'Réinitialistion de mot de passe', component: LostPassword()},
	{path: '/profil/site', name: 'Gestion du site', component: BackOfficeSite()},
	{path: '/:pathMatch(.*)*', name: '404', component: PageNotFound()},
];

const router = createRouter({
	history: createWebHistory(),
	routes
});

router.beforeEach((to) => {
	document.title = config.appName + ' - ' + to.name;
	
	return true;
});

export default router;
