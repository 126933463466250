
export default () => {
	return {
		template: require('/src/pages/pageNotFound.html'),
		data() {
			return {
			};
		},
		created() {
			this.$router.replace('/404');
		},
		methods: {
		},
	};
};
