
export default Object.freeze({
	appName: 'Terriskop',
	contactEmail: 'contact@terriskop.com',
	domain: 'terriskop.com',
	serverRest: 'https://test-api.terriskop.com',
	tinymceOptions: {
		license_key: 'gpl',
		skin: 'oxide-dark',
		content_css: 'dark',
		statusbar: false,
		menubar: false,
		toolbar: 'undo redo | fontsize bold italic alignleft aligncenter alignright underline | image link | bullist numlist outdent indent | forecolor backcolor | emoticons',
		plugins: [
			'emoticons', 'image', 'link', 'advlist', 'lists'
		],
	},
});
