import API from '../../modules/API';
import immFormActu from '../../components/imm-form-actu';

export default () => {
	return {
		name: 'ActuEdit',
		template: require('./actuEdit.html'),
		components: {
			immFormActu
		},
		data() {
			return {
				actu: null,
				actuError: null,
			};
		},
		created() {
			this.getActualite(this.$route.params.id);
		},
		mounted() {
		},
		methods: {
			async getActualite(id) {
				try {
					this.actu = await API.getActu(id);
				} catch (exception) {
					this.actuError = this.errorManager(exception);
				}
			},
			async deleteActu(id) {
				try {
					await API.removeActu(id);
					this.$router.push('/profil/actualites/');
				} catch (exception) {
					this.actuError = this.errorManager(exception);
				}
			},
		},
	};
};
