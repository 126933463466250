import ImmFormActu from '../../components/imm-form-actu';

export default () => {
	return {
		name: 'ActuAdd',
		template: require('./actuAdd.html'),
		components: {
			ImmFormActu,
		},
	};
};
