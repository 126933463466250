import ImmFormContact from '../../components/imm-form-contact';

export default () => {
	return {
		name: 'ContactAdd',
		template: require('./contactAdd.html'),
		components: {
			ImmFormContact,
		},
		data() {
			return {
			};
		},
		created() {
		},
		mounted() {
		},
		methods: {
		},
	};
};
